var WebNotification = (function() {
	var privateScope = {
		subscriptionCallbacks : [],
		readyCallbacks		  : [],
	};

	var publicScope = {
		log : function(message) {
			console.log(message);
		},
		checkRequirements : function() {
			// do initial checks, if all required features are supported, register the worker

			// Are Notifications supported in the service worker?  
			if (!('showNotification' in ServiceWorkerRegistration.prototype)) {  
				publicScope.log('serviceWorker: Notifications aren\'t supported');
				return false;
			} else {
				publicScope.log('serviceWorker: Notifications are supported');
			}

			if (!('pushManager' in ServiceWorkerRegistration.prototype)) {
				publicScope.log('serviceWorker: PushManager aren\'t supported');
				return false;
			}

			// Has user blocked notifications?
			if (Notification.permission === 'denied') {  
				publicScope.log('serviceWorker: You have blocked notifications. See browser documentation to check how to enable it again');
				return false;
			}

			// Is push messaging available? PushManager object is a must.
			if (!('PushManager' in window)) {  
				publicScope.log('serviceWorker: Push messaging isn\'t supported');
				return false;
			} else {
				publicScope.log('serviceWorker: Push messaging <strong>*is*</strong> supported');
			}

			return true;
		},

		init : function() {
			if (!publicScope.checkRequirements()) {
				publicScope.log('init: doesnt met requirements');
				return false;
			}

			// We need the service worker registration to check for a subscription  
			navigator.serviceWorker.ready.then(publicScope.worker.ready).catch(function(e) {
				publicScope.log('ready: Unable to ready. ' + e);
			});
		},

		subscribe : function(callback, errCallback) {
			navigator.serviceWorker.ready.then(function(serviceWorkerRegistration) {
				publicScope.log('subscribe: ready.then');
				serviceWorkerRegistration.pushManager.subscribe({userVisibleOnly: true}).then(function(subscription) {
					publicScope.log('subscribe: subscribe');

					if (callback && typeof callback == 'function') {
						callback.apply(this, [subscription]);
					} else {
						publicScope.log('unsubscribe: Unable to execute unsubscribe.');
					}

					return true;
				}).catch(function(e) {
					if (errCallback && typeof errCallback == 'function') {
						errCallback.apply(this, [e]);
					} else {
						publicScope.log('subscribe: Unable to subscribe to push. ' + e);
					}
				});
			}).catch(function(e) {
				publicScope.log('subscribe: Unable to ready. ' + e);
			});  
		},

		unsubscribe : function(callback, errCallback) {
			navigator.serviceWorker.ready.then(function(serviceWorkerRegistration) {
				serviceWorkerRegistration.pushManager.getSubscription().then(function(pushSubscription) {
					pushSubscription.unsubscribe().then(function(subscription) {
						if (callback && typeof callback == 'function') {
							callback.apply(this, [subscription]);
						} else {
							publicScope.log('unsubscribe: Unable to execute unsubscribe.');
						}

						return true;
					}).catch(function(e) {
						if (errCallback && typeof errCallback == 'function') {
							errCallback.apply(this, [e]);
						} else {
							publicScope.log('unsubscribe: Unable to unsubscribe. ' + e);
						}
					});
				}).catch(function(e) {
					publicScope.log('unsubscribe: Unable to unsubscribe. ' + e);
				});
			}).catch(function(e) {
				publicScope.log('unsubscribe: Unable to ready. ' + e);
			});
		},

		worker : {
			ready : function(serviceWorkerRegistration) {
				// Do we already have a push message subscription? 
				publicScope.log('serviceWorker: ready registration');

				serviceWorkerRegistration
					.pushManager
					.getSubscription()
					.then(publicScope.worker.onSubscription)
					.catch(function(err) {
						publicScope.log('Error during getSubscription()<br />' + err);  
					});

				var scope = this;
				privateScope.readyCallbacks.forEach(function(func) {
					if (func && typeof func == 'function') {
						func.apply(scope, [serviceWorkerRegistration]);
					} else {
						publicScope.log('unsubscribe: Unable to execute unsubscribe.');
					}
				});
			},

			onReady : function(callback) {
				privateScope.readyCallbacks.push(callback);
			},

			onSubscriptionChange : function(callback) {
				privateScope.subscriptionCallbacks.push(callback);
			},

			onSubscription : function(subscription) {  
				if (!subscription) {
					// Update UI - Enable Push Button
					publicScope.log('serviceWorker: User has not subscribed to push');
				}

				var scope = this;
				privateScope.subscriptionCallbacks.forEach(function(func) {
					if (func && typeof func == 'function') {
						func.apply(scope, [subscription]);
					} else {
						publicScope.log('unsubscribe: Unable to execute unsubscribe.');
					}
				});
			}
		}
	};

	return publicScope;
}());
